// main.js

import { createApp } from "vue";
import App from "./App.vue";

import router from "./router";
import { createPinia } from "pinia";
import piniaPluginPersistedstate from "pinia-plugin-persistedstate";
import { useStore } from "./useStore"; // Assurez-vous que le chemin est correct
import { supabase } from "../supabase";

import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";

// Créer l'application Vue
const app = createApp(App);

// Créer une instance de Pinia
const pinia = createPinia();

// Utiliser le plugin pour la persistance de l'état si nécessaire
pinia.use(piniaPluginPersistedstate);

// Options de configuration pour vue-toastification (optionnel)
const toastOptions = {
  position: "top-right",
  timeout: 5000,
  closeOnClick: true,
  pauseOnFocusLoss: true,
  pauseOnHover: true,
  draggable: true,
  draggablePercent: 0.6,
  showCloseButtonOnHover: false,
  hideProgressBar: false,
  closeButton: "button",
  icon: true,
  rtl: false,
  theme: "colored", // Vous pouvez choisir entre 'light', 'dark', 'colored'
  // ... autres options
};

// Utiliser Vue Toastification comme plugin **avant** d'utiliser Pinia et Router
app.use(Toast, toastOptions);

// Ajouter Pinia à l'application
app.use(pinia);

// Ajouter le router à l'application
app.use(router);

// Monter l'application
app.mount("#app");

// Appeler le store après que Pinia a été ajouté à l'application
const store = useStore();

// Gérer l'état d'authentification avec Supabase après que le store a été créé
supabase.auth.onAuthStateChange((event) => {
  if (event === "SIGNED_IN") {
    store.setLoggedIn(true);
  } else if (event === "SIGNED_OUT") {
    store.setLoggedIn(false);
  }
});
